








































































































































import { Component, Vue } from "vue-property-decorator";
import Prism from "prismjs";
import "@/assets/themes/prism-darcula.css";
import SchemaExampleOnly from "@/components/SchemaExampleOnly.vue";

@Component({
	components: {
		SchemaExampleOnly,
	},
})
export default class ProductCommand extends Vue {
	mounted() {
		Prism.highlightAll();
	}
}
